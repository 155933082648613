<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <div class="banner_title b_b_space font-size_60">
            <div>虎珀•费用管理</div>
          </div>
          <!--<div class="banner_title b_b_space font-size_60">服务商证据管理</div>-->
          <p class="banner_desc b_b_space font-size_20">
            适用于工业下游费用结算和各级推广服务商结算场景。智能算法和数据处理技术，显著提高财务和运营工作效率。</p>
          <p class="banner_desc b_b_space font-size_20">
            它以工业发货、终端流向和销售订单为基础数据源，与S2P的其他产品模块深度集成，实现了业务财务一体化、可视化。</p>
        </div>
      </div>
      <div class="l_r_space t__space">
        <h2 class="nav__title">核心功能</h2>
        <div class="tab_Jurisdiction">
          <a-tabs
            default-active-key="1"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane key="1" tab="全场景政策维护">
              <a-row :gutter="80" type="flex" class="row_view-box">
                <a-col  :xs="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :md="{ span: 9, offset: 3 }"
                        :lg="{ span: 9, offset: 3 }"
                        :xl="{ span: 9, offset: 3 }"
                        :xxl="{ span: 9, offset: 3 }">
                  <img
                    style="max-width: 100%"
                    src="@/assets/img/png/hp_1.png"
                    alt="全场景政策维护"
                    class="view_width-full"
                  />
                </a-col>
                <a-col
                  :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :xxl="12"
                  class="text_left"
                >
                  <p class="Jurisdiction_title">全场景政策维护</p>
                  <p class="Jurisdiction_desc">
                    支持多种政策维度的自由组合，包括客户/产品/周期/区域等。除了政策之外，支持不同档位的奖励政策，以满足企业的实际业务需求，同时满足费用结算的数字化管理，可处理返款、佣金、提成奖金结算等场景。
                  </p>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="2" tab="一体化结算流程">
              <a-row :gutter="80" type="flex" class="row_view-box">
                <a-col :xs="{ span: 24 }"
                       :sm="{ span: 24 }"
                       :md="{ span: 9, offset: 3 }"
                       :lg="{ span: 9, offset: 3 }"
                       :xl="{ span: 9, offset: 3 }"
                       :xxl="{ span: 9, offset: 3 }">
                  <img
                    style="max-width: 100%"
                    src="@/assets/img/png/hp_2.png"
                    alt="一体化结算流程"
                    class="view_width-full"
                  />
                </a-col>
                <a-col
                  :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :xxl="12"
                  class="text_left"
                >
                  <p class="Jurisdiction_title">一体化结算流程</p>
                  <div class="Jurisdiction_desc">
                    <p>
                     实现"申请-销售/采购-收/付款"一体化管理维护，通过权限分配，明确部门职责，提高工作效率。
                    </p>
                  </div>
                </a-col>
              </a-row></a-tab-pane
            >
            <a-tab-pane key="3" tab="多元化结算模式">
              <a-row :gutter="80" type="flex" class="row_view-box">
                <a-col  :xs="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :md="{ span: 9, offset: 3 }"
                        :lg="{ span: 9, offset: 3 }"
                        :xl="{ span: 9, offset: 3 }"
                        :xxl="{ span: 9, offset: 3 }">
                  <img
                    style="max-width: 100%"
                    src="@/assets/img/png/hp_3.png"
                    alt="多元化结算模式"
                    class="view_width-full"
                  />
                </a-col>
                <a-col
                  :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  :xxl="12"
                  class="text_left"
                >
                  <p class="Jurisdiction_title">多元化结算模式</p>
                  <div class="Jurisdiction_desc">
                    <p>
                      支持服务商模式、配送商、佣金制、承包制、授信制等不同模式的结算场景
                    </p>

                  </div>
                </a-col>
              </a-row></a-tab-pane
            >
          </a-tabs>
        </div>
      </div>
      <div class="l_r_space t__space" style="background-color: #eee">
        <h2 class="nav__title">为什么选择正也科技</h2>
        <h3 class="nav__desc" style="padding: 0.1rem 0 0.4rem 0">
          行业有深度 数据有态度
        </h3>
        <div>
          <a-row :gutter="20" type="flex">
            <a-col
              style="padding: 0 0.1rem 20px 0.1rem"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in softWareList"
              :key="index"
            >
              <a-card>
                <a-row style="align-items: center" type="flex">
                  <a-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" :xxl="10"
                    ><img
                      :src="item.img_url"
                      :alt="item.title"
                      style="width: 100%; max-width: 80px"
                  /></a-col>
                  <a-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14" :xxl="14"
                    ><div style="min-height: 1.1rem">
                      <h3 class="soft_title text_left b_b_space font-size_26">
                        {{ item.title }}
                      </h3>
                      <p class="soft_desc text_left b_b_space font-size_16">
                        {{ item.desc }}
                      </p>
                    </div></a-col
                  >
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import soft_1 from "@/assets/img/png/soft_1.png";
import soft_2 from "@/assets/img/png/soft_2.png";
import soft_3 from "@/assets/img/png/soft_3.png";
import soft_4 from "@/assets/img/png/soft_4.png";
import soft_5 from "@/assets/img/png/soft_5.png";
import soft_6 from "@/assets/img/png/soft_6.png";
export default {
  name: "mg",
  beforeCreate() {},
  components: { Footer, HeaderMenu },
  data() {
    return {
      softWareList: [
        {
          title: "行业深度",
          desc:
            "正也科技核心成员为医药营销管理咨询专家和多年医药行业软件开发实施经验。",
          img_url: soft_1,
        },
        {
          title: "资源丰富",
          desc: "公司拥有海量行业主数据供客户选择，以及丰富的关键管理识别经验",
          img_url: soft_2,
        },
        {
          title: "产品规范",
          desc: "S2P支持全面数据颗粒化、追求灵活配置，应对企业未来管理需求",
          img_url: soft_3,
        },
        {
          title: "敏捷开发",
          desc: "基于主数据平台深度颗粒化，不断快速迭代，满足企业的个性化需求",
          img_url: soft_4,
        },
        {
          title: "致力创新",
          desc: "S2P专注于研究“营销方法论”、“营销新算法”，“让数据有态度”",
          img_url: soft_6,
        },
        // {
        //   title: "致力于创新",
        //   desc: "公司专注于研究如何使用信息化的方法，以“数”助销",
        //   img_url: soft_6,
        // },
      ],
    };
  },
  created() {},
  methods: {
    handleSubmit() {},
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.soft_title {
  padding: 0.15rem 0 0.1rem 0;
}
.soft_desc {
  color: $color-fixGray;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/hup_bg.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.5rem;
    left: 13.5%;
    .banner_desc {
      width: 7.2rem;
      padding-top: 0.3rem;
    }
  }
}
.row_view-box {
  align-items: center;
  padding: 0rem 0 0.8rem 0;
}
</style>
